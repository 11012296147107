import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import axios from "axios";
import router from './router'
import store from '@/store/index'

Vue.config.productionTip = false

function tokenInterceptor () {
    axios.interceptors.request.use(config => {
        if (Vue.prototype.$keycloak.authenticated) {
            config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
        }
        return config
    }, error => {
        return Promise.reject(error)
    })
}

Vue.use(VueKeyCloak, {
    init: {
        onLoad: 'check-sso',
        flow: 'standard',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        checkLoginIframe: true,
        scope: 'openid email profile roles groups',
    },
    config: {
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID
    },
    logout: {
        redirectUri: process.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URI
    },
    onInitError: (error) => {
        console.error(error)
    },
    onReady: () => {
        tokenInterceptor()
        new Vue({
            router,
            store,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    }
})
