import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import Link from '@/store/models/Link';
import Count from "@/store/models/Count";

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, {
    axios,
    dataTransformer: (response) => {
        switch (typeof response.data) {
            case 'string': {
                const JSON_PROTECTION_PREFIX = /^\)]}',?\n/
                return JSON.parse(response.data.replace(JSON_PROTECTION_PREFIX, '').trim())
            }
            case 'object':
                return response.data
        }
    }
})

// Create a new instance of Database.
const database = new VuexORM.Database()

database.register(Link)
database.register(Count)

export default function () {
    return new Vuex.Store({
        plugins: [VuexORM.install(database)],
        modules: {},

        // enable strict mode (adds overhead!)
        // for dev mode only
        strict: process.env.DEBUGGING
    })
}
