<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer v-model="drawer" app>
        <!-- -->
        <ProfileCard></ProfileCard>
      </v-navigation-drawer>

      <v-app-bar app color="orange lighten-2" elevation="0">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>affx.eu - URL shortener</v-toolbar-title>
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-main>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <router-view/>
        </v-container>
      </v-main>

      <v-footer app>
        &copy; 2021 icod.de Darko Luketic
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import ProfileCard from "@/components/ProfileCard";
export default {
  name: 'App',
  components: {ProfileCard},
  data: () => ({drawer: null})
}
</script>

<style>
</style>
