<template>
  <div>
    <v-card outlined >
      <v-card-text>
      <v-avatar>
        <v-icon>mdi-account-circle</v-icon>
      </v-avatar>
        <span v-if="this.$keycloak.authenticated">{{this.$keycloak.tokenParsed.name}}</span>
        <span v-else>Guest</span>
      </v-card-text>
      <v-card-actions>
        <v-btn text block @click="logout" v-if="this.$keycloak.authenticated">Logout</v-btn>
        <v-btn text block @click="login" v-else>Login or Register</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProfileCard",
  methods: {
    login() {
      this.$keycloak.loginFn()
    },
    logout() {
      this.$keycloak.logoutFn()
    }
  }
}
</script>

<style scoped>

</style>
