import {Model} from '@vuex-orm/core'
import Count from "@/store/models/Count";

export default class Link extends Model {
    static entity = 'link'

    static fields() {
        return {
            created_at: this.attr(null),
            updated_at: this.attr(null).nullable(),

            id: this.uid(),
            title: this.string(''),
            sub: this.string(''),
            url: this.string(''),
            short: this.string(''),
            human: this.string(''),

            counter: this.hasOne(Count, 'link_id')
        }
    }
}
