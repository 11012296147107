import {Model} from '@vuex-orm/core'
import Link from "@/store/models/Link";

export default class Count extends Model {
    static entity = 'count'

    static fields() {
        return {
            id: this.uid(),
            link_id: this.uid(),
            count: this.number(0),

            link: this.belongsTo(Link, 'link_id')
        }
    }
}
